import React from "react";
import Layout from "../components/layout/Layout";

import "./index.css";
import SEO from "../components/seo/SEO";
import Hero from "../components/layout/Hero";

export default function Index() {
  return (
    <>
      <SEO
        title="Tietoliikennekursseja yrityksille"
        description="Tutustu monipuoliseen kurssitarjontaamme ja ota yhteyttä."
      />
      <Layout>
        <Hero image="goals">Kiitos!</Hero>
        <section className="my-5 lg:w-8/12">
          Tietosi on vastaanotettu. Olemme yhteydessä sinuun mahdollisimman
          pian.
        </section>
      </Layout>
    </>
  );
}
